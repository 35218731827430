<template>
  <div>
    <el-form class="query-form" inline>
      <el-form-item label="">
        <el-checkbox v-model="checked" @change="checkChange">离京人员</el-checkbox>
      </el-form-item>
      <el-form-item label="日期" >
        <el-date-picker
          v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            size="small"
            format="yyyy-MM-dd"
            value-format="yyyy-MM-dd hh:mm:ss"
            @change="changeTime"
          >
          </el-date-picker>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
          v-model="query.name"
          size="small"
          label-width="80px"
        ></el-input>
      </el-form-item>
      <el-form-item label="电话">
        <el-input
          v-model="query.mobile"
          size="small"
          label-width="80px"
        ></el-input>
      </el-form-item>
      <el-form-item label="机构">
        <el-select v-model="query.institution" size="small" placeholder="请选择"  clearable>
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.name"
            :value="item.name">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="地区">
        <el-cascader
                size="small"
                :options="options_"
                v-model="selectedOptions"
                @change="handleChange"
                :props="{ checkStrictly: true }"
                placeholder="请选择"
            ></el-cascader>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" size="small" @click="search" @keydown.enter="search">查询</el-button>
      </el-form-item>
      <el-form-item>
        <el-button size="small" @click="newReset()">重置</el-button>
      </el-form-item>
    </el-form>
    <div class="main-content">
      <div style="margin-bottom: 20px">
        <el-button type="primary" size="small" @click="exportExcel"
            ><i class="el-icon-download"></i> 导出Excel</el-button
          >
        </el-upload>
      </div>
      <el-table :data="tableData" style="width: 100%" border stripe>
        <el-table-column prop="name" label="姓名" >
        </el-table-column>
        <el-table-column prop="mobile" label="电话" >
        </el-table-column>
        <el-table-column prop="goTime" label="更新日期">
          <template slot-scope="scope">
              {{formatTime(scope.row.goTime)}}
            </template>  
        </el-table-column>
        <el-table-column prop="aimAddress" label="最新位置">
        </el-table-column>
        <el-table-column  label="最高风险状态">
          <template slot-scope="scope">
            <span class="tag" :class="'level'+scope.row.inLevel" v-if="scope.row.inLevel">{{scope.row.inLevel==1?'低风险地区':scope.row.inLevel==2?'中风险地区':'高风险地区'}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="institution"  label="机构">
        </el-table-column>
        <el-table-column label="行程记录" >
          <template slot-scope="scope">
            <el-button type="text" @click="showDialog(scope.row)">查看</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total,sizes,prev, pager, next"
        style="text-align: right; margin-top: 20px"
        @size-change="sizeChange"
        @prev-click="prevClick"
        @next-click="nextClick"
        @current-change="pageCurrent"
        :page-size="query.ps"
        :total="total"
      >
      </el-pagination>
    </div>
    <!-- 行程记录 -->
    <tripdialog ref="trip"></tripdialog>
  </div>
</template>

<script>
import { tripList, tripDetail, excelExport } from "@/api/api";
import {getTreeData} from "@/api/organization"
import format from "@/mixins/format";
import page from "@/mixins/page";
import axios from "axios";
import tripdialog from "@/components/trip"
import {
  provinceAndCityData,
  regionData,
  provinceAndCityDataPlus,
  regionDataPlus,
  CodeToText,
  TextToCode,
} from "element-china-area-data";
export default {
  data() {
    return {
      query: {
        pn: 1,
        ps: 10,
      },
      checked: false,
      total: 0,
      tableData: [],
      time: "",
      dialogVisible: false,
      history: [],
      options_: provinceAndCityData,
      selectedOptions: [],
      options: [
        
      ],
    };
  },
  mixins: [format, page],
  mounted() {
    this.getList();
    this.getTreeData()
  },
  methods: {
    newReset(){
      this.checked = false
      this.reset()
    },
    getList() {
      tripList(this.query).then((res) => {
        console.log(res);
        this.total = res.data.data.total;
        this.tableData = res.data.data.list;
      });
    },
    async getTreeData(){
      let res = await getTreeData()
      this.options = res.data.data.filter(item=>item.type==0)
    },
    changeTime(e) {
      (this.query.startTime = e[0]), (this.query.endTime = e[1]);
    },
    showDialog(obj) {
      this.$refs.trip.show(obj.mobile)
    },
    exportExcel() {
      excelExport().then((res) => {
        const data = res.data;
        const url = window.URL.createObjectURL(
          new Blob([data], {
            type:
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          })
        );
        const link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", "excel.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    checkChange(e) {
      this.query.inBeijing = e ? 2 : 0;
    },
    handleChange(e){
      regionData.forEach(parent=>{
        if(parent.value==e[0]){
          this.query.province = parent.label.substr(0,parent.label.length-1)
          parent.children.forEach(item=>{
            if(item.value == e[1]){
              if(item.label=='市辖区'){
                this.query.city = this.query.province
              }else{
                this.query.city = item.label.substr(0,item.label.length-1)
              }
            }
          })
        }
      })
    },
    
  },
  components:{
    tripdialog
  }
};
</script>

<style scoped>
.level2 {
  color: #e8a270;
}
.level1 {
  color: #878786;
}
.level3 {
  color: #e36f6b;
}
</style>